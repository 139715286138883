import bankApi from 'apis/bankApi';
import walletApi from 'apis/walletApi';
import { RootState, useAppSelector } from 'cache/store';
import classNames from 'classnames';
import LoadingIcon from 'components/loading/LoadingIcon';
import { Bank3rdItem, BankDepositModel, CreateBankOrder3rdPayload, EWalletOrderDetail, PayloadOrder3rdDeposit } from 'models/bankModel';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import accountStyles from '../account/account-option/account-option.style';
import Bank3rdDeposit from './Bank3rdDeposit';
import BankDeposit from './BankDeposit';
import styles from './deposit.styles';
import EWalletDeposit from './EwalletDeposit';
import { sha256Hash } from 'utils/function';
import { KEY_HASH } from 'constants/common';
const cdnUrl = process.env.REACT_APP_CDN_URL;

interface TabDeposit {
    label: string;
    value: 'BANK' | 'QICK' | 'EWALLET';
}
const tabInit: TabDeposit[] = [
    {
        label: 'Chuyển Khoản Ngân Hàng',
        value: 'BANK',
    },
    {
        label: 'Chuyển Khoản Trực Tuyến',
        value: 'QICK',
    },
];

function DepositPage() {
    const { userInfo } = useAppSelector((state: RootState) => state.auth);

    const [tabSelect, setTabSelect] = useState<'BANK' | 'QICK' | 'EWALLET'>('BANK');
    const [bankQick, setBankQick] = useState<BankDepositModel[]>([]);
    const [bankTransfer, setBankTransfer] = useState<BankDepositModel[]>([]);
    const [depositSelect, setDepositSelect] = useState<BankDepositModel>({} as BankDepositModel);
    const [depositCodeList, setDepositCodeList] = useState<string[]>([]);
    const [codeTransfer, setCodeTransfer] = useState('');
    const [loading, setLoading] = useState(false);
    const [minMaxDeposit, setMinMaxDeposit] = useState<{ min: number; max: number }>({ min: 50000, max: 300000000 });

    const [bank3rdList, setBank3rdList] = useState<Bank3rdItem[]>([]);
    const [linkDeposit3rd, setLinkDeposit3rd] = useState('');

    const [eWalletBankList, setEWalletBankList] = useState<BankDepositModel[]>([]);
    const [eWalletOrder, setEWalletOrder] = useState<EWalletOrderDetail | null>(null);

    const [tabDeposit, setTabDeposit] = useState<TabDeposit[]>(tabInit);
    const [isYeahPay, setIsYeahPay] = useState(false);

    useEffect(() => {
        const initDeposit = async () => {
            try {
                const { data } = await walletApi.depositInit({});
                if (!data.msg) {
                    const bankQickData = data.bankcardlist.filter((item: BankDepositModel) => item.tabtype === 'ONLINE');
                    const bankTransferData = data.bankcardlist.filter((item: BankDepositModel) => item.tabtype === 'OFFLINE');
                    const eWalletData: BankDepositModel[] = data.bankcardlist.filter((item: BankDepositModel) => item.tabtype === 'EWALLET');
                    const { dmax, dmin } = data;

                    if (eWalletData.length > 0) {
                        const newTabList: TabDeposit[] = [...tabInit, { label: 'Ví Điện Tử', value: 'EWALLET' }];
                        setTabDeposit(newTabList);
                    }

                    setEWalletBankList(eWalletData);
                    setBankQick(bankQickData);
                    setBankTransfer(bankTransferData);
                    setDepositCodeList(data.listDepositCode);
                    setMinMaxDeposit({ min: dmin, max: dmax });
                } else {
                    toast.error(data.msg);
                }
            } catch (error) {
                console.log(error);
            }
        };
        initDeposit();
    }, []);

    const changeTabBank = (tab: 'BANK' | 'QICK' | 'EWALLET') => {
        if (tab === tabSelect) return;
        setTabSelect(tab);
        setDepositSelect({} as BankDepositModel);
        if (linkDeposit3rd) setLinkDeposit3rd('');

        if (tab === 'EWALLET') {
            getCurrentEwalletOrder();
        }
    };

    const changeDepositType = (banktype: BankDepositModel, type: 'ONLINE' | 'OFFLINE') => {
        if (banktype.providercode !== 'YEAH_PAY') {
            setIsYeahPay(false);
        }
        if (banktype.id === depositSelect.id) {
            return;
        }
        setDepositSelect(banktype);
        //chuyển khoản ngân hàng
        if (type === 'OFFLINE') {
            if (banktype.providercode !== 'MAIN') {
                if (banktype.providercode === 'QIPAY' || banktype.providercode === 'MOPAY') {
                    getBankQIPay3rd(banktype.providercode);
                } else if (banktype.providercode === 'YEAH_PAY') {
                    setIsYeahPay(true);
                } else {
                    geBank3rd(banktype.providercode);
                }
            } else {
                let codeDepositMap = depositCodeList.length > 0 ? depositCodeList[Math.floor(Math.random() * depositCodeList.length)] : 'UIDT';
                codeDepositMap = `${codeDepositMap}${userInfo.id}`;
                setCodeTransfer(codeDepositMap);

                const payloadQrCode = {
                    accountNo: banktype.bankcard,
                    accountName: banktype.showbankcard,
                    acqId: banktype.bankcode, //mã BIN ngân hàng
                    addInfo: codeDepositMap,
                    format: 'text',
                    template: 'qr_only',
                };
                generateQrCode(payloadQrCode, banktype);
            }
        } else if (type === 'ONLINE') {
            //nạp tiền bên thứ 3
            geBank3rd(banktype.providercode);
        }
    };

    const generateQrCode = async (payload: any, bankItem: BankDepositModel) => {
        try {
            const response = await fetch('https://api.vietqr.io/v2/generate', {
                method: 'POST',

                headers: {
                    'Content-Type': 'application/json',
                    'x-client-id': '424eff55-1bdd-4996-899b-5ac56547e620',
                    'x-api-key': '6e63c6dc-1749-491d-8c25-cf6815f68769',
                },

                body: JSON.stringify(payload),
            });
            const res = await response.json();
            if (res.code === '00') {
                const newDepositSelect = { ...bankItem, qrurl: res.data.qrDataURL };
                setDepositSelect(newDepositSelect);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const geBank3rd = async (bankCode: string) => {
        setLoading(true);
        try {
            const { data } = await walletApi.getBankDeposit3rd(bankCode);
            if (!data.msg) {
                const bankSubObj = JSON.parse(data.banks);
                const bankSubArray = [];
                for (const key in bankSubObj) {
                    const itemBankSub = {
                        code: key,
                        name: bankSubObj[key],
                        icon: key.toLowerCase(),
                    };
                    bankSubArray.push(itemBankSub);
                }
                setBank3rdList(bankSubArray);
            } else {
                toast.error(data.msg);
                setBank3rdList([]);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const getBankQIPay3rd = async (paymentType: 'QIPAY' | 'MOPAY') => {
        setLoading(true);
        try {
            const { data } = await bankApi.getListBankQi({ paymentType });
            if (!data.msg) {
                setBank3rdList(data.data);
            } else {
                toast.error(data.msg);
                setBank3rdList([]);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const submit3rdDeposit = async (data: { amount: number; bankCode: string }) => {
        const payload = {
            ...data,
            providerCode: depositSelect.providercode,
        };
        if (payload.providerCode === 'QIPAY' || payload.providerCode === 'MOPAY' || payload.providerCode === 'YEAH_PAY') {
            orderQiPay(payload);
        } else {
            setLoading(true);
            try {
                const { data } = await walletApi.createOrderBank3rd(payload);
                if (!data.msg) {
                    setLinkDeposit3rd(data.url);
                } else {
                    toast.error(data.msg);
                }
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }
    };

    const orderQiPay = async (data: CreateBankOrder3rdPayload) => {
        setLoading(true);
        let payload: PayloadOrder3rdDeposit = {
            amount: data.amount,
            paymentType: data.providerCode,
            enc: sha256Hash(`${userInfo.loginname}${KEY_HASH}${data.amount}`),
        };
        if (!isYeahPay) payload.type = data.bankCode;
        try {
            const { data } = await bankApi.createOrderBank(payload);
            if (!data.msg) {
                getDetailOrderQiPay(payload.paymentType as any, data.data);
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const getDetailOrderQiPay = async (paymentType: 'QIPAY' | 'MOPAY' | 'YEAH_PAY', orderId: string) => {
        setLoading(true);
        const payload = {
            id: orderId,
            paymentType,
        };
        try {
            const { data } = await bankApi.getDetailOrder(payload);
            if (!data.msg) {
                setLinkDeposit3rd(data.data.redirect_ssl);
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const getCurrentEwalletOrder = async (orderId?: string) => {
        setLoading(true);
        try {
            const { data } = await walletApi.getDetailEwalletOrder(!orderId ? {} : { id: orderId });
            if (!data.msg) {
                //không có lệnh nạp nào trước đó
                if (!data.data) {
                    setEWalletOrder(null);
                } else {
                    setEWalletOrder(data.data);
                }
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const createOrderEwallet = async (payload: { amount: number; type: string }) => {
        setLoading(false);
        try {
            const { data } = await walletApi.createEwalletOrder(payload);
            console.log(data);
            if (!data.msg) {
                getCurrentEwalletOrder(data.data);
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const cancelOrderEWallet = async (orderId: string) => {
        setLoading(true);
        try {
            const { data } = await walletApi.cancelEwalletDeposit({ id: orderId });
            if (!data.msg) {
                getCurrentEwalletOrder();
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const completelOrderEWallet = async (orderId: string) => {
        setLoading(true);
        try {
            const { data } = await walletApi.completeEwalletDeposit({ id: orderId });
            if (!data.msg) {
                setEWalletOrder(null);
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className={`${accountStyles.wrap}`}>
                <h6 className={accountStyles.title}>Nạp Tiền</h6>
                <div className={styles.tabList}>
                    {tabDeposit.map((tab) => (
                        <div className={classNames(styles.tabItem, { active: tabSelect === tab.value })} key={tab.value} onClick={() => changeTabBank(tab.value)}>
                            {tab.label}
                        </div>
                    ))}
                </div>
                {!linkDeposit3rd ? (
                    <div className={accountStyles.mw800}>
                        {/* bank tab */}
                        {tabSelect === 'BANK' && (
                            <div className={styles.bankGroup}>
                                <h6 className={styles.titleBankGroup}>
                                    {Object.keys(depositSelect).length > 0 ? (
                                        <>
                                            {depositSelect.providercode === 'MAIN' ? (
                                                <>
                                                    1. Bạn đang chọn ngân hàng <span>{depositSelect.accid}</span>
                                                </>
                                            ) : (
                                                <>
                                                    1. Bạn đang chọn thanh toán qua cổng <span>{depositSelect.name}</span>
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        '1. Chọn ngân hàng'
                                    )}
                                </h6>
                                <div className={styles.bankList}>
                                    {bankTransfer.map((bank) => (
                                        <div className={classNames(styles.bankItem, { active: depositSelect.id === bank.id })} key={bank.id} onClick={() => changeDepositType(bank, 'OFFLINE')}>
                                            <div className={styles.bankItemInner}>
                                                <img className={`${styles.bankIcon}`} src={`${cdnUrl}/bank-list/${bank.icon}`} alt="" />
                                                {depositSelect.id === bank.id && <img className={styles.iconBankSelect} src={require('assets/images/icons/tick-bank.png')} alt="" />}
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <div className=""></div>
                            </div>
                        )}

                        {/* qick tab */}
                        {tabSelect === 'QICK' &&
                            (bankQick.length > 0 ? (
                                <div className={styles.bankGroup}>
                                    <h6 className={styles.titleBankGroup}>
                                        {Object.keys(depositSelect).length > 0 ? (
                                            <>
                                                1. Bạn đang chọn thanh toán qua cổng <span>{depositSelect.name}</span>
                                            </>
                                        ) : (
                                            '1. Chọn cổng thanh toán'
                                        )}
                                    </h6>
                                    <div className={styles.bankList}>
                                        {bankQick.map((bank) => (
                                            <div className={classNames(styles.bankItem, { active: depositSelect.id === bank.id })} key={bank.id} onClick={() => changeDepositType(bank, 'ONLINE')}>
                                                <div className={styles.bankItemInner}>
                                                    <img className={`${styles.bankIcon}`} src={`${cdnUrl}/bank-list/${bank.icon}`} alt="" />
                                                    {depositSelect.id === bank.id && <img className={styles.iconBankSelect} src={require('assets/images/icons/tick-bank.png')} alt="" />}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <div className={styles.channelMaintain}>
                                    <img src={require('assets/images/icons/bank-maintain.png')} alt="" />
                                    <p>Hệ thống đang bảo trì.</p>
                                </div>
                            ))}

                        {/* nạp qua chuyển khoản ngân hàng */}
                        {tabSelect === 'BANK' && depositSelect.providercode === 'MAIN' && <BankDeposit depositSelect={depositSelect} codeTransfer={codeTransfer} minMaxDeposit={minMaxDeposit} />}

                        {/* ví điện tử */}
                        {tabSelect === 'EWALLET' && (
                            <EWalletDeposit
                                onCancelOrder={cancelOrderEWallet}
                                onComplete={completelOrderEWallet}
                                eWalletOrder={eWalletOrder}
                                onCreateOrder={createOrderEwallet}
                                bank3rdList={eWalletBankList}
                            />
                        )}

                        {/* nạp chuyển khoản qua bên thứ 3 */}
                        {((depositSelect.tabtype === 'OFFLINE' && depositSelect.providercode !== 'MAIN') || depositSelect.tabtype === 'ONLINE') && (
                            <Bank3rdDeposit isYeahPay={isYeahPay} depositSelect={depositSelect} onSubmit3rd={submit3rdDeposit} minMaxDeposit={minMaxDeposit} bank3rdList={bank3rdList} />
                        )}
                    </div>
                ) : (
                    <div className={styles.iframeDeposit}>
                        <iframe src={linkDeposit3rd} title="link-deposit"></iframe>
                    </div>
                )}
            </div>
            {loading && <LoadingIcon />}
        </>
    );
}

export default DepositPage;
