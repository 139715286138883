import Fingerprint2 from 'fingerprintjs2';
import { FastLotto } from 'interfaces/fast-lotto-bet';
import { FastMienBacLotto } from 'interfaces/fast-mienbac-lotto';
import { MienbacDBLotto } from 'interfaces/mienbac-db-lotto';
import { MienbacLotto, MienbacVipLotto } from 'interfaces/mienbac-lotto';
import { MiennamLotto, MiennamVipLotto } from 'interfaces/miennam-lotto';
import { MientrungLotto } from 'interfaces/mientrung-lotto';
import { LotteryInterface, LotterySub, NumHighlight } from 'models/lotteryModel';
import gameListInterface from 'pages/account/stats-general.interface';
import CryptoJS from 'crypto-js';

export const checkWhiteSpaceString = (text: string) => {
    const isValid = /^\S+$/.test(text);
    return isValid;
};

export const removeSeperateNumber = (numberText: string) => {
    return numberText.replace(/[,]/g, '');
};

export const getFullLinkUrl = () => {
    let link = window.location.protocol + '//' + window.location.hostname;
    if (window.location.port !== '') {
        link += ':' + window.location.port;
    }
    return link;
};

export const rangeYear = (start: number, end: number) => {
    return (new Array(end - start) as any).fill().map((d: any, i: any) => i + start);
};

export const checkIsTypeLotteryMienNam = (lotteryId: number) => {
    const groupLotteryGame = gameListInterface.filter((group) => group.id === 'xosotruyenthong' || group.id === 'xosonhanh');
    let gameList: any[] = [];
    groupLotteryGame.forEach((game) => {
        gameList = [...gameList, ...game.gameGroup.filter((item) => item.gameId)];
    });
    gameList = gameList.filter((game) => game.id === 'mientrung' || game.id === 'miennam' || game.id === 'xosonhanh');
    gameList = gameList.map((game) => game.gameId);

    return gameList.includes(lotteryId);
};

export const checkIsTypeLotteryMienBac = (lotteryId: number) => {
    const groupLotteryGame = gameListInterface.filter((group) => group.id === 'xosotruyenthong' || group.id === 'sieutocmienbac');
    let gameList: any[] = [];
    groupLotteryGame.forEach((game) => {
        gameList = [...gameList, ...game.gameGroup.filter((item) => item.gameId)];
    });
    gameList = gameList.filter((game) => game.id === 'mienbac' || game.id === 'sieutocmienbac');
    gameList = gameList.map((game) => game.gameId);

    return gameList.includes(lotteryId);
};

export const checkIsFastLottery = (lotteryId: number) => {
    const groupLotteryGame = gameListInterface.filter((group) => group.id === 'xosonhanh' || group.id === 'sieutocmienbac');
    let gameList: any[] = [];
    groupLotteryGame.forEach((game) => {
        gameList = [...gameList, ...game.gameGroup.filter((item) => item.gameId)];
    });

    gameList = gameList.map((game) => game.gameId);
    return gameList.includes(lotteryId);
};

export const getTitleLottery = (lotteryId: number): string => {
    const groupLotteryGame = gameListInterface.filter((group) => group.id === 'xosotruyenthong' || group.id === 'xosonhanh');
    let gameList: any[] = [];
    groupLotteryGame.forEach((game) => {
        gameList = [...gameList, ...game.gameGroup.filter((item) => item.gameId)];
    });
    const lotterySelect = gameList.find((game) => game.gameId === lotteryId);
    if (lotterySelect) return lotterySelect.label;
    return '';
};

export const getLotteryFace = (ltid: number): LotteryInterface[] => {
    if (ltid > 49 && ltid < 105 && ltid !== 83 && ltid !== 85 && ltid !== 89) {
        return JSON.parse(JSON.stringify(FastLotto));
    } else if (ltid > 104 && ltid < 126) {
        return JSON.parse(JSON.stringify(MiennamLotto));
    } else if (ltid > 125 && ltid < 140) {
        return JSON.parse(JSON.stringify(MientrungLotto));
    } else if (ltid === 200) {
        return JSON.parse(JSON.stringify(MienbacLotto));
    } else if (ltid === 201) {
        return JSON.parse(JSON.stringify(MienbacDBLotto));
    } else if (ltid === 202 || ltid === 206) {
        return JSON.parse(JSON.stringify(MienbacVipLotto));
    } else if (ltid === 203 || ltid === 204) {
        return JSON.parse(JSON.stringify(MiennamVipLotto));
    } else if ([140, 141, 142, 143, 144, 145].includes(ltid)) {
        return JSON.parse(JSON.stringify(FastMienBacLotto));
    } else {
        return [];
    }
};

export const cloneObject = (obj: any) => {
    return JSON.parse(JSON.stringify(obj));
};

export const splitInputLottery = (slNums: string) => {
    slNums = slNums.replace(/((\s+)?,(\s+)?)/g, ',');
    slNums = slNums.replace(/((\s+)?;(\s+)?)/g, ';');
    slNums = slNums.replace(/\s+/g, ' ');
    slNums = slNums.trim();
    return slNums.split(/\;|\,| /);
};

export const extractTypeInNumbers = (input: string) => {
    if (!input) return [];
    return input.replace(/\D/g, ' ').split(' ');
};

export const getFormatHightLightLottery = (typeInputId: string, type: 'MB' | 'MBDB' | 'MN') => {
    let numHightLight: NumHighlight = {} as NumHighlight;
    let lotteryFace: LotteryInterface[] = [];
    if (type === 'MN') {
        lotteryFace = MiennamLotto;
    }
    if (type === 'MB') {
        lotteryFace = MienbacLotto;
    }
    if (type === 'MBDB') {
        lotteryFace = MienbacDBLotto;
    }
    lotteryFace.forEach((face) => {
        if (face.subTabs && Object.keys(numHightLight).length === 0) {
            face.subTabs.forEach((tab) => {
                if (tab.typeIputID === typeInputId) {
                    numHightLight = tab.numHl;
                }
            });
        }
    });
    return numHightLight;
};

export const scrollToTopPage = () => {
    const rootEl = document.getElementById('appRoot');
    if (rootEl) {
        rootEl.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }
};

export const formatCodeString = (codes: string) => {
    if (codes) {
        codes.replace(/&/g, ',');
        const numberList = codes
            .split(',')
            .sort((a, b) => Number(a) - Number(b))
            .join(', ');
        // return codes.replace(/&/g, ',');
        return numberList;
    }
    return '';
};

export const formatNumber = (number: number) => {
    if (number >= 1e9) {
        return number / 1e9 + ' tỷ';
    } else if (number >= 1e6) {
        return number / 1e6 + ' triệu';
    } else if (number >= 1e3) {
        return number / 1e3 + ' nghìn';
    }
    return number.toString();
};

export const getDeviceInfo = async () => {
    const getFinger = (components: Fingerprint2.Component[]) => {
        const values = components.map((component) => component.value);
        return {
            uuid: Fingerprint2.x64hash128(values.join(''), 88),
            timezone: values[9],
            platform: values[16],
            resolution: values[6].join('x'),
        };
    };

    return new Promise<{ uuid: string; timezone: string; platform: string; resolution: string }>((resolve, reject) => {
        if ((window as any).requestIdleCallback) {
            requestIdleCallback(function () {
                Fingerprint2.get(function (components) {
                    resolve(getFinger(components));
                });
            });
        } else {
            setTimeout(function () {
                Fingerprint2.get(function (components) {
                    resolve(getFinger(components));
                });
            }, 500);
        }
    });
};

export const sha256Hash = (message: string): string => {
    return CryptoJS.SHA256(message).toString(CryptoJS.enc.Hex);
};

export const getIpDevice = async (): Promise<string | null> => {
    try {
        const res = await fetch('https://api64.ipify.org?format=json');
        const data = await res.json();
        return data.ip;
    } catch (error) {
        return null;
    }
};
