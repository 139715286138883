import authApi from 'apis/authApi';
import commonApi from 'apis/commonApi';
import miniGameApi from 'apis/miniGameApi';
import { RootState, useAppDispatch, useAppSelector } from 'cache/store';
import ToastRoot from 'components/ToastAlert';
import MessageAlertCustom from 'components/alert-message/MessageAlertCustom';
import alertMsg from 'components/alert-message/alert-message';
import LoadingIcon from 'components/loading/LoadingIcon';
import MiniGameIcon from 'components/minigame/MiniGameIcon';
import Modal from 'components/modal/Modal';
import SupportArea from 'components/support-area/SupportArea';
import BoxTransferMiniGame from 'components/transfer/BoxTransferMiniGame';
import vi from 'date-fns/locale/vi';
import { cacheUserInfo } from 'features/authSlice';
import { cacheInfoApp } from 'features/configSlice';
import MainLayout from 'layouts/MainLayout';
import { MiniGamePath, MiniGameType } from 'models/miniGameModel';
import Register from 'pages/reg/Register';
import { useEffect, useRef, useState } from 'react';
import { registerLocale } from 'react-datepicker';
import { isMobile } from 'react-device-detect';
import toast from 'react-hot-toast';
import { Navigate, Route, Routes } from 'react-router-dom';
import PrivateRoute from 'routes/AuthRoute';
import { mainRoutes } from 'routes/routes';
import actionMiniGame from 'utils/actionMinigame';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import styles from './app.style';
import { DEVICE_INFO } from 'constants/common';
import 'assets/js/Winwheel.js';
registerLocale('vi', vi);

let timeoutId: any;

function App() {
    const rootDivRef = useRef<HTMLDivElement>(null);
    const miniGameIframeRef = useRef<HTMLIFrameElement>(null);
    const stopRequestUserInfo = useRef<boolean>(false);
    const isLoggedRef = useRef<boolean>(false);

    const dispatch = useAppDispatch();

    const { isLogged } = useAppSelector((state: RootState) => state.auth);

    const [loading, setLoading] = useState(false);
    const [urlMiniGame, setUrlMiniGame] = useState('');
    const [showBoxTransfer, setShowBoxTransfer] = useState(false);

    useEffect(() => {
        /**
         * redirect if a mobile or tablet device
         */
        const redirectToWap = () => {
            if (isMobile) {
                window.location.replace(`${window.location.protocol}//m.${window.location.host}`);
            }
        };
        redirectToWap();
    }, []);

    useEffect(() => {
        const loadFingerprint = async () => {
            const fp = await FingerprintJS.load();
            const result = await fp.get();
            const timezone = (result.components?.timezone as any)?.value;
            const platform = (result.components?.platform as any)?.value;
            const uuid = result.visitorId;
            DEVICE_INFO.timezone = timezone;
            DEVICE_INFO.platform = platform;
            DEVICE_INFO.uuid = uuid;
            DEVICE_INFO.resolution = `${window.innerWidth}x${window.innerHeight}`;
        };
        loadFingerprint();
    }, []);

    useEffect(() => {
        //listener event when click button close minigame
        window.addEventListener('message', (event) => {
            // IMPORTANT: check the origin of the data!
            if (event.data.action === actionMiniGame.CLOSE_GAME) {
                setUrlMiniGame('');
            }

            if (event.data.action === actionMiniGame.OPEN_TRANSFER) {
                console.log(event.data);
                setShowBoxTransfer(true);
            }
        });
    }, []);

    useEffect(() => {
        const getInfoApp = async () => {
            try {
                const { data } = await commonApi.getVersionApp({});
                if (!data.msg) {
                    dispatch(cacheInfoApp(data));
                }
            } catch (error) {
                console.log(error);
            }
        };
        getInfoApp();
    }, []);

    useEffect(() => {
        document.addEventListener('visibilitychange', () => {
            stopRequestUserInfo.current = document.hidden;
            if (!document.hidden) {
                timeoutId && clearTimeout(timeoutId);
                getUserInfo();
            }
        });
        return () => {
            document.removeEventListener('visibilitychange', () => {});
        };
    }, []);

    useEffect(() => {
        isLoggedRef.current = isLogged;
    }, [isLogged]);

    const getUserInfo = async () => {
        if (isLoggedRef.current) {
            try {
                const { data } = await authApi.getUserInfo();
                if (!data.errorCode) {
                    dispatch(cacheUserInfo(data));
                }
            } catch (error) {
                timeoutId && clearTimeout(timeoutId);
                console.log(error);
            } finally {
                timeoutId && clearTimeout(timeoutId);
                timeoutId = setTimeout(() => {
                    if (!stopRequestUserInfo.current && isLoggedRef.current) {
                        getUserInfo();
                    }
                }, 10000);
            }
        }
    };

    const launchMiniGame = async (type: MiniGamePath, gameType: MiniGameType, cb: () => void) => {
        if (!isLogged) {
            alertMsg('Vui lòng đăng nhập', 'error', true);

            return;
        }
        setLoading(true);
        try {
            const { data } = await miniGameApi.launchGame({ gameType });
            if (!data.msg) {
                let url = `${process.env.REACT_APP_MINIGAME_URL}/${type}/?encrypted=${data.encrypted}`;
                // if (isLocal) {
                // } else {
                //     const origin = window.location.origin.replace('https://', 'https://albgame.');
                //     url = `${origin}/${type}/?encrypted=${data.encrypted}`;
                // }
                setUrlMiniGame(url);
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
            cb && cb();
        }
    };

    const handleTransferSucess = () => {
        if (miniGameIframeRef.current) {
            miniGameIframeRef.current.contentWindow?.postMessage({ action: actionMiniGame.LOAD_BALANCE }, '*');
        }
    };

    return (
        <>
            <div className={styles.root} ref={rootDivRef} id="appRoot">
                <Routes>
                    {/* main routes */}
                    {mainRoutes.map((route, index) => {
                        const Page = route.page;
                        return <Route key={index} path={route.path} element={<MainLayout>{route.isPrivate ? <PrivateRoute component={route.page} /> : <Page />}</MainLayout>} />;
                    })}
                    <Route path="/reg/:id" element={<Register />} />
                    <Route path="/reg" element={<Register />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>

                <MiniGameIcon onLaunchGame={launchMiniGame} />
                {/* support block */}
                <SupportArea ref={rootDivRef} />

                <div className={`${styles.miniGameIframe} ${urlMiniGame ? 'show' : ''}`}>{urlMiniGame && <iframe ref={miniGameIframeRef} src={urlMiniGame} title="minigame"></iframe>}</div>

                {/* popup chuyển quỹ nhanh */}
                <Modal isShow={showBoxTransfer} onClose={() => setShowBoxTransfer(false)} width="520px">
                    {showBoxTransfer ? <BoxTransferMiniGame onClose={() => setShowBoxTransfer(false)} onSuccess={handleTransferSucess} /> : <></>}
                </Modal>
            </div>
            {/* <Helmet>
                <title>My Title</title>
                <meta name="description" content="Helmet application" />
            </Helmet> */}
            <ToastRoot />
            <MessageAlertCustom />
            {loading && <LoadingIcon />}
        </>
    );
}

export default App;
