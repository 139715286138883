import { css } from '@emotion/css';
import commonApi from 'apis/commonApi';
import lotteryApi from 'apis/lotteryApi';
import userApi from 'apis/userApi';
import bannerDefault from 'assets/images/banner-default.jpg';
import { RootState, useAppDispatch, useAppSelector } from 'cache/store';
import alertMsg from 'components/alert-message/alert-message';
import Button from 'components/button/Button';
import IntroApp from 'components/intro-app/IntroApp';
import LiveCasinoSection from 'components/live-casino/LiveCasinoSection';
import LoadingIcon from 'components/loading/LoadingIcon';
import MarqueeComponent from 'components/marquee/Marquee';
import Modal from 'components/modal/Modal';
import RuleApp from 'components/rule-app/RuleApp';
import TimeNow from 'components/time-now/TimeNow';
import FastTransferGame from 'components/transfer/FastTransferGame';
import VqmmIcon from 'components/vqmm/vqmm-icon';
import { ltOpenDay } from 'constants/common';
import globalStyle from 'constants/global.style';
import { colors } from 'constants/theme';
import { toggleFastLotteryHome } from 'features/configSlice';
import { toggleShowFirstNotice } from 'features/msgSystemSlice';
import { LotteryFastMb, LotteryFastMn, LotteryMenu } from 'interfaces/lottery-menu';
import { Game3rdMenuItem, SportMatchHotModel } from 'models/commonModel';
import { NotiDetailModel } from 'models/notiModel';
import { PromoDetailResponse } from 'models/promotionModel';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { scrollToTopPage } from 'utils/function';
import styles from './homepage.styles';
import FastLotteryHome from './lottery/FastLotteryHome';
import TodayLotteryHome from './lottery/TodayLotteryHome';
import MatchFootball from './match-football/MatchFootball';
import PromotionHome from './promotion/PromotionHome';
import luckySpinApi from 'apis/luckySpinApi';

const cdnUrl = process.env.REACT_APP_CDN_URL;

function HomePage() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { menuGame, showFirstNotice } = useAppSelector((state: RootState) => state.system);
    const { isLogged } = useAppSelector((state: RootState) => state.auth);

    const [marqueeTextList, setMarqueeTextList] = useState<PromoDetailResponse[]>([]);
    const [bannerList, setBannerList] = useState<PromoDetailResponse[]>([]);

    const [liveCasinoList, setLiveCasinoList] = useState<Game3rdMenuItem[]>([]);
    const [sportList, setSportList] = useState<Game3rdMenuItem[]>([]);

    const [lotteryTodayList, setLotteryTodayList] = useState<any[]>([]);
    const [promotionList, setPromotionList] = useState<PromoDetailResponse[]>([]);
    const [loading, setLoading] = useState(false);

    const [msgDetail, setMsgDetail] = useState<NotiDetailModel>({} as NotiDetailModel);
    const [showMsgDetail, setShowMsgDetail] = useState(false);
    const [gameTransferSelect, setGameTransferSelect] = useState<Game3rdMenuItem | null>(null);
    const [matchSportList, setMatchSportList] = useState<SportMatchHotModel[]>([]);

    useEffect(() => {
        let todayMenu = ltOpenDay[new Date().getDay()];
        let todayLottery = [];
        for (let i = 0; i < LotteryMenu.length; i++) {
            for (let j = 0; j < LotteryMenu[i].submenu.length; j++) {
                if (todayMenu.search(LotteryMenu[i].submenu[j].lotteryID.toString()) > -1) {
                    todayLottery.push(LotteryMenu[i].submenu[j].lotteryID);
                }
            }
        }
        const payload = { lotteryId: todayLottery.join(',').replace(',201', '') };
        const getLotteryOffical4DBean = async () => {
            try {
                const { data } = await lotteryApi.lotteryOffical4DBean(payload);
                const lotteryData = data.listOfficial.map((item: any) => ({
                    listJackpost: getJackpots(item.cursitem.winnumber),
                    lotteryname: item.lotteryname,
                    issue: JSON.parse(item.curissue).issue,
                    opentime: item.cursitem.opentime,
                    endtime: item.cursitem.endtime,
                    servertime: item.servertime,
                    lotteryid: item.lotteryid,
                    hisopentime: item.cursitem.hisopentime,
                }));
                setLotteryTodayList(lotteryData.reverse());
            } catch (error) {
                console.log(error);
            }
        };
        getLotteryOffical4DBean();
        return () => {
            dispatch(toggleFastLotteryHome(false));
        };
    }, []);

    useEffect(() => {
        if (menuGame.length > 0) {
            const casinoData = menuGame.find((item) => item.gametype === 'LC');
            const sportData = menuGame.find((item) => item.gametype === 'SB');
            if (casinoData?.child) {
                setLiveCasinoList(casinoData.child.slice(0, 10));
            }
            if (sportData?.child) {
                setSportList(sportData.child.slice(0, 4));
            }
        }
    }, [menuGame]);

    useEffect(() => {
        const initMsgSystemBean = async () => {
            try {
                const { data } = await commonApi.getMsgSystemBean({});
                if (!data.msg) {
                    //danh sách thông báo marquee top
                    const marqueeData: PromoDetailResponse[] = data.msglist.filter((item: PromoDetailResponse) => item.status === 1);

                    //danh sách banner top
                    const bannerImg: PromoDetailResponse[] = data.msglist
                        .filter((item: PromoDetailResponse) => item.status === 4 && item.photourl !== '')
                        .sort((a: PromoDetailResponse, b: PromoDetailResponse) => b.param3 - a.param3);
                    // danh sách khuyến mãi
                    const promotionData: PromoDetailResponse[] = data.msglist.filter((item: PromoDetailResponse) => item.status === 0);

                    if (!showFirstNotice) {
                        // popup first login
                        const notiList: PromoDetailResponse[] = data.msglist.filter((item: PromoDetailResponse) => item.status === 2);
                        if (notiList.length > 0) {
                            setTimeout(() => {
                                selectDetailMsg(notiList[0].id);
                            }, 1500);
                        }
                        dispatch(toggleShowFirstNotice(true));
                    }

                    setPromotionList(promotionData.slice(0, 4));
                    setMarqueeTextList(marqueeData);
                    setBannerList(bannerImg);
                }
            } catch (error) {
                console.log(error);
            }
        };
        initMsgSystemBean();
    }, []);

    //get list sport match hot
    useEffect(() => {
        const getSportHot = async () => {
            try {
                const { data } = await userApi.listSportView({});
                if (!data.msg) {
                    const dataSport = data.reslist.filter((item: any) => item.status === 1 && checkInTime(item.startdate));
                    setMatchSportList(dataSport);
                }
            } catch (error) {
                console.log(error);
            }
        };
        getSportHot();
    }, []);

    const getJackpots = (winnumber: string) => {
        const arrayWinNumber = winnumber.split(',');
        if (arrayWinNumber.length > 0) {
            return arrayWinNumber[0].split('');
        }
        return [];
    };

    const selectGame = (game: Game3rdMenuItem) => {
        if (!isLogged) {
            alertMsg('Vui lòng đăng nhập');
            return;
        }
        setGameTransferSelect(game);
    };

    const checkInTime = (startTime: string) => {
        const timeover = 7200000;
        const now = new Date();
        const timestart = new Date(startTime + ' GMT+7');
        const diffTime = now.getTime() - timestart.getTime();
        if (timeover > diffTime) {
            return true;
        }
        return false;
    };

    const selectDetailMsg = async (id: number) => {
        setLoading(true);
        try {
            const { data } = await commonApi.sysMsgBeanInfo({ id });
            if (!data.msg) {
                setShowMsgDetail(true);
                setMsgDetail({
                    title: data.sysmsg.title,
                    content: data.sysmsg.content,
                    photourl: data.sysmsg.photourl || '',
                });
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const goToPromotionPage = () => {
        navigate('/promotion');
        scrollToTopPage();
    };

    const goToLotteryPage = (ltId: number) => {
        navigate(`/lottery?ltId=${ltId}`);
        scrollToTopPage();
    };
    return (
        <>
            <div className={styles.homeContainer}>
                <div className={styles.marquee}>
                    <div className={globalStyle.container}>
                        <div className="d-flex">
                            <div className={styles.timeNow}>
                                <TimeNow />
                                <img className={styles.megaphone} src={require('assets/images/icons/mega_home.png')} alt="" />
                            </div>
                            <div className={styles.slideText}>
                                <MarqueeComponent dataList={marqueeTextList} onShowDetail={selectDetailMsg} />
                            </div>
                        </div>
                    </div>
                </div>
                {/* swiper banner */}
                <div className={styles.bannerSlide}>
                    {bannerList.length === 0 && <img className={styles.imgBanner} src={bannerDefault} alt="" />}
                    <Swiper
                        autoplay={{
                            delay: 10000,
                        }}
                        modules={[Pagination, Autoplay]}
                        pagination={{ clickable: true }}
                        speed={600}
                        loop
                    >
                        {bannerList.map((item) => (
                            <SwiperSlide key={item.id}>
                                <img className={styles.imgBanner} src={item.photourl || bannerDefault} alt="" loading="lazy" onError={(e: any) => (e.target.src = bannerDefault)} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                {/* live casino */}
                <section className={`${styles.section} live-secion`}>
                    {/* <img className="snow-head" src={require('assets/images/xmas/new-year-head.png')} alt="" /> */}
                    <div className={`${globalStyle.container} title`}>
                        <TitleSection title="LIVE CASINO" icon={require('assets/images/icons/live_casino.png')} hideShowAll />
                        <LiveCasinoSection gameList={liveCasinoList} gametype="LC" onSelect={selectGame} />
                    </div>

                    {/* <img className="decor-icon" src={require('assets/images/xmas/decor-6.png')} alt="" /> */}
                    {/* <img className="decor-icon decor-right" src={require('assets/images/xmas/decor-7.png')} alt="" /> */}
                </section>

                {/* football */}
                <section className={`${styles.section} ${styles.football}`}>
                    <div className={globalStyle.container}>
                        <div style={{ marginBottom: '24px' }}>
                            <TitleSection title="THỂ THAO" bgTitle titleColor={colors.blackText} hideShowAll icon={require('assets/images/icons/football.png')} />
                        </div>

                        <div className={styles.footballContent}>
                            {/* match day football */}
                            <div className={styles.footballHot}>
                                <MatchFootball sportList={matchSportList} />
                            </div>

                            {/* football game list */}
                            <div className={styles.footballWrap}>
                                {sportList.map((game, index) => (
                                    <div className={styles.gameItem} key={game.providercode}>
                                        <img src={`${cdnUrl}/image/game/web/background/SB/${index}.png`} alt="" />

                                        <img style={{ left: '16px' }} className="logo-game" src={`${cdnUrl}/image/game/web/logo/SB/${game.providercode}.png`} alt="" />
                                        <div className={styles.btnPlayGame} style={{ left: '16px' }}>
                                            <Button text="CHƠI NGAY" bg="none" click={() => selectGame(game)} borderColor={colors.orange} rounded bgHover={colors.orange} textSize="12px" />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>

                {/* lottery  */}
                <section className={`${styles.section} section-lt`}>
                    {/* <img className="snow-head" src={require('assets/images/xmas/decor-new-year-8.png')} alt="" /> */}
                    <div className={globalStyle.container}>
                        {/* xo so nhanh mien nam */}
                        <div className={styles.wrapFastLT}>
                            <div style={{ marginBottom: '18px' }}>
                                <TitleSection italicTitle title="XỔ SỐ SIÊU TỐC" icon={require('assets/images/icons/ball_lottery.png')} onShowAll={() => goToLotteryPage(50)} />
                            </div>
                            <FastLotteryHome lotteryData={LotteryFastMn.submenu.slice(1, 5)} />
                            <img className="ball-title-icon" src={require('assets/images/icons/ball-title-lt.png')} alt="" />
                        </div>

                        {/* xo so nhanh mien bac */}
                        <div style={{ marginTop: '40px' }}>
                            <div style={{ marginBottom: '18px' }}>
                                <TitleSection italicTitle title="SIÊU TỐC MIỀN BẮC" icon={require('assets/images/icons/ball_lottery.png')} onShowAll={() => goToLotteryPage(140)} />
                            </div>
                            <FastLotteryHome lotteryData={LotteryFastMb.submenu.slice(1, 5)} />
                        </div>

                        {/* xo so hom nay */}
                        <div style={{ marginTop: '40px' }}>
                            <div style={{ marginBottom: '18px' }}>
                                <TitleSection italicTitle title="XỔ SỐ HÔM NAY" icon={require('assets/images/icons/ball_lottery_1.png')} hideShowAll />
                            </div>
                            <TodayLotteryHome lotteryList={lotteryTodayList} />
                        </div>
                    </div>
                    {/* <img className="decor-icon left" src={require('assets/images/xmas/decor-new-year-9.png')} alt="" /> */}
                    {/* <img className="decor-icon right" src={require('assets/images/xmas/decor-new-year-10.png')} alt="" /> */}
                </section>

                {/* promotion */}
                <section className={styles.section}>
                    <div className={globalStyle.container}>
                        <TitleSection italicTitle title="KHUYẾN MÃI MỚI NHẤT" icon={require('assets/images/icons/gift.png')} onShowAll={goToPromotionPage} />
                        <PromotionHome promoList={promotionList} />
                    </div>
                </section>

                {/* intro page */}
                <IntroApp />

                <div className={globalStyle.container} style={{ paddingBottom: '24px' }}>
                    <RuleApp />
                </div>

                {/* VQMM  */}
                <VqmmIcon
                    onLaunchGame={async () => {
                        if (isLogged) {
                            const { data }: any = await luckySpinApi.initLuckySpin({});

                            if (data?.errorCode === 1) {
                                alertMsg('Đã kết thúc sự kiện');
                            } else {
                                navigate(`/lucky-win`);
                            }
                        } else {
                            alertMsg('Vui lòng đăng nhập');
                        }
                    }}
                />
            </div>
            <Modal isShow={showMsgDetail} onClose={() => setShowMsgDetail(false)} width="520px" closeClickBackdrop>
                <MsgDetail data={msgDetail} onClose={() => setShowMsgDetail(false)} />
            </Modal>

            {/* popup chuyển quỹ nhanh */}
            <Modal isShow={Boolean(gameTransferSelect)} onClose={() => setGameTransferSelect(null)} width="520px">
                {gameTransferSelect ? <FastTransferGame gameData={gameTransferSelect} onClose={() => setGameTransferSelect(null)} /> : <></>}
            </Modal>
            {loading && <LoadingIcon />}
        </>
    );
}

interface TitleSectionProps {
    title: string;
    icon: string;
    hideShowAll?: boolean;
    bgTitle?: boolean;
    titleColor?: string;
    italicTitle?: boolean;
    onShowAll?: () => void;
}

const TitleSection = (props: TitleSectionProps) => {
    const { title, icon, hideShowAll, onShowAll, bgTitle, titleColor, italicTitle } = props;
    const selectShowAll = () => {
        if (onShowAll) onShowAll();
    };
    return (
        <div className={`${styles.title} ${italicTitle && styles.italic}`}>
            <div className={`d-flex align-items-center ${bgTitle ? styles.bgTitle : ''}`}>
                <img className={styles.titleIcon} src={icon} alt="" />
                <h6 style={{ color: titleColor || '' }}>{title}</h6>
            </div>
            {!hideShowAll && (
                <button type="button" className={styles.btnViewAll} onClick={selectShowAll}>
                    <span>XEM TẤT CẢ</span>
                    <img src={require('assets/images/icons/arow_left_orange.png')} alt="" />
                </button>
            )}
        </div>
    );
};

interface MsgDetailProps {
    data: NotiDetailModel;
    onClose: () => void;
}

const MsgDetail = (props: MsgDetailProps) => {
    const { data, onClose } = props;
    return (
        <div className={globalStyle.bgPopup(colors.bgPopupDrak)}>
            <h6 className="title">{data.title}</h6>
            {data.photourl !== null && data.photourl !== '' && <img style={{ width: '100%' }} src={data.photourl} alt="" />}
            <div className="" dangerouslySetInnerHTML={{ __html: data.content }}></div>
            <div className={msgDetailStyle.wrapClose}>
                <button type="button" onClick={onClose} className="btn-primary">
                    Đóng
                </button>
            </div>
        </div>
    );
};

const msgDetailStyle = {
    wrapClose: css`
        text-align: center;
        margin-top: 16px;
        button {
            width: 180px;
            border-radius: 8px;
            padding: 8px 12px;
        }
    `,
};

export default HomePage;
