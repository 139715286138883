import { FormValuesRegister, RegisterPayload } from 'models/authModel';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import styles from './auth-header.style';
import CheckBox from 'components/checkbox/CheckBox';
import { msgAlertInput } from 'constants/messageAlert';
import globalStyle from 'constants/global.style';
import { appName } from 'config/app.config';
import { sha256Hash } from 'utils/function';
import { KEY_HASH } from 'constants/common';

type InputId = 'un' | 'pwd' | 'zjpwd' | 'nickname' | 'phone' | 'email';
interface InputItem {
    id: InputId;
    label: string;
    holder: string;
    inputType: string;
    require: boolean;
    togglePass?: boolean;
    pattern?: { value: RegExp; message: string };
    minLength?: { value: number; message: string };
    maxLength?: { value: number; message: string };
}
const listInput: InputItem[] = [
    {
        id: 'un',
        label: 'Tên Đăng Nhập',
        holder: msgAlertInput.username.invalid,
        inputType: 'text',
        require: true,
        pattern: {
            value: /^\S+$/,
            message: msgAlertInput.generic.invalid('Tên đăng nhập'),
        },

        maxLength: {
            value: 30,
            message: msgAlertInput.generic.maxLength(30),
        },
    },
    {
        id: 'pwd',
        label: 'Mật Khẩu',
        holder: msgAlertInput.password.invalid,
        inputType: 'password',
        require: true,
        togglePass: true,
        maxLength: {
            value: 30,
            message: msgAlertInput.generic.maxLength(30),
        },
    },
    {
        id: 'zjpwd',
        label: 'Mật Khẩu Thanh Toán',
        holder: msgAlertInput.password.invalid,
        inputType: 'password',
        require: true,
        togglePass: true,
        maxLength: {
            value: 30,
            message: msgAlertInput.generic.maxLength(30),
        },
    },
    {
        id: 'nickname',
        label: 'Biệt Danh',
        holder: 'Nhập ký tự chữ và số',
        inputType: 'text',
        require: true,
        maxLength: {
            value: 30,
            message: msgAlertInput.generic.maxLength(30),
        },
    },
    {
        id: 'phone',
        label: 'Số Điện Thoại',
        holder: 'Nhập vào số điện thoại',
        inputType: 'text',
        require: true,
        maxLength: {
            value: 12,
            message: msgAlertInput.generic.maxLength(12),
        },
    },
    {
        id: 'email',
        label: 'Email (Không bắt buộc)',
        holder: 'Nhập đúng định dạng email',
        inputType: 'email',
        require: false,
    },
];

interface Props {
    onRegister: (payload: RegisterPayload) => void;
}
function RegisterForm(props: Props) {
    const { onRegister } = props;

    const [isCheckedTOF, setIsCheckedTOF] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [showPassPay, setShowPassPay] = useState(false);
    const {
        handleSubmit,
        register,
        setFocus,
        setError,
        formState: { errors },
    } = useForm<FormValuesRegister>();

    const handleRegister: SubmitHandler<FormValuesRegister> = async (values) => {
        if (!isCheckedTOF) {
            toast.error('Vui lòng chọn chấp nhận các điều khoản');
            return;
        }
        if (values.pwd === values.zjpwd) {
            setFocus('zjpwd');
            setError('zjpwd', {
                type: 'value',
                message: 'Mật khẩu thanh toán không được giống với đăng nhập',
            });
            return;
        }
        const payload = {
            ...values,
            loginname: values.un.toLowerCase(),
            email: values.email || `${values.un.toLowerCase().trim()}_noreply@gmail.com`,
            agentid: '10020', // prod
            id: '1635310', //id tkweb
            enc: sha256Hash(`${values.un.toLowerCase()}${KEY_HASH}${values.pwd}${values.nickname}`),
        };

        onRegister(payload);
    };

    return (
        <form className={styles.form} onSubmit={handleSubmit(handleRegister)}>
            {listInput.map((item, index) => (
                <div className={styles.inputWrap} key={index}>
                    <label htmlFor={item.id}>{item.label}</label>
                    <input
                        type={item.id === 'pwd' ? (!showPass ? item.inputType : 'text') : item.id === 'zjpwd' ? (!showPassPay ? item.inputType : 'text') : item.inputType}
                        id={item.id}
                        placeholder={item.holder}
                        className={`input-common ${styles.input}`}
                        {...register(item.id, {
                            required: item.require,
                            pattern: item.pattern,
                            maxLength: item.maxLength,
                            minLength: item.minLength,
                        })}
                    />
                    {/* toggle password login */}
                    {item.togglePass && item.id === 'pwd' && (
                        <div className={styles.togglePass} onClick={() => setShowPass(!showPass)}>
                            {showPass ? <img src={require('assets/images/icons/eye_on.png')} alt="" /> : <img src={require('assets/images/icons/eye_off.png')} alt="" />}
                        </div>
                    )}

                    {/* toggle password pay */}
                    {item.togglePass && item.id === 'zjpwd' && (
                        <div className={styles.togglePass} onClick={() => setShowPassPay(!showPassPay)}>
                            {showPassPay ? <img src={require('assets/images/icons/eye_on.png')} alt="" /> : <img src={require('assets/images/icons/eye_off.png')} alt="" />}
                        </div>
                    )}

                    {/* base error  */}
                    <div className={styles.errorInput}>{(errors as any)[item.id] && <small>{(errors as any)[item.id].message || 'Vui lòng nhập trường này!'}</small>}</div>
                </div>
            ))}
            <CheckBox isChecked={isCheckedTOF} onToggleCheck={(value) => setIsCheckedTOF(value)}>
                <p className={styles.textTOF}>
                    Tôi đã đọc và đồng ý với các{' '}
                    <a href="/help?view=1" target="_blank">
                        Điều khoản và Điều kiện
                    </a>{' '}
                    của {appName}
                </p>
            </CheckBox>

            <button type="submit" className={globalStyle.btnSecondary} style={{ marginTop: '24px' }}>
                Đăng Ký
            </button>
        </form>
    );
}

export default RegisterForm;
