import { css, keyframes } from '@emotion/css';
import { colors } from 'constants/theme';
import liveCasinoBg from 'assets/images/bg/live_casino.jpg';
import newYearCasino from 'assets/images/bg/new-year-casino.png';
import footballBg from 'assets/images/bg/football.jpg';
import footballBgNewYear from 'assets/images/bg/footballBgNewYear.png';
import lotteryBg from 'assets/images/bg/lottery_home.jpg';
import { snowflakesFall, snowflakesShake } from 'constants/global.style';

const glass = keyframes`
  0%{
    background-position:120%;
    opacity: 1;
  }
  100%{
    background-position:-160%;
    opacity: 0;
  }
`;
const homePageStyles = {
    homeContainer: css`
        background: ${colors.bgDark};
    `,
    marquee: css`
        background: #001717;
    `,
    megaphone: css`
        width: 20px;
        margin-left: 12px;
    `,
    timeNow: css`
        display: flex;
        align-items: center;
        flex: 0 0 156px;
    `,
    slideText: css`
        flex: 1;
        max-width: calc(100% - 156px);
        position: relative;
        height: 40px;
        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 0;
            width: 120px;
            height: 100%;
            z-index: 2;
            pointer-events: none;
        }
        &::before {
            background: linear-gradient(90deg, rgba(18, 24, 35, 1) 0%, rgba(26, 32, 44, 0) 100%);
            left: 0;
        }
        &::after {
            right: 0;
            background: linear-gradient(270deg, rgba(18, 24, 35, 1) 0%, rgba(26, 32, 44, 0) 100%);
        }
    `,
    bannerSlide: css`
        .swiper-pagination {
            bottom: 23px !important;
            .swiper-pagination-bullet {
                background: #003535;
                transition: 0.25s;
                opacity: 1;
                width: 22px;
                height: 6px;
                border-radius: 10px;
            }
            .swiper-pagination-bullet-active {
                background: #00ffff;
                /* width: 22px; */
                border-radius: 10px;
            }
        }
    `,
    imgBanner: css`
        width: 100%;
    `,
    section: css`
        padding: 36px 0;
        &.section-lt {
            position: relative;
            .snow-head {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
            }
            .decor-icon {
                position: absolute;
                top: 20px;
                z-index: 0;
                height: 280px;
                &.left {
                    left: 15px;
                }
                &.right {
                    right: 15px;
                }
            }
        }
        &.live-secion {
            /* background: url(${newYearCasino}) no-repeat; */
            background-size: 100% 100%;
            transform: translateY(-3px);
            margin-bottom: -3px;
            position: relative;
            z-index: 1;
            padding-top: 35px;
            .title {
                position: relative;
                z-index: 1;
            }
            .snow-head {
                position: absolute;
                top: -50px;
                left: 0;
                width: 100%;
            }
            .decor-icon {
                position: absolute;
                top: 40px;
                width: 80px;
            }
            .decor-right {
                right: 0;
                transform: rotate(360deg);
            }
        }
    `,

    title: css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
        h6 {
            font-size: 22px;
            margin-left: 10px;
            font-weight: 600;
            color: ${colors.white};
        }
    `,
    italic: css`
        h6 {
            font-style: italic;
        }
    `,

    titleIcon: css`
        width: 36px;
    `,
    btnViewAll: css`
        display: flex;
        align-items: center;
        padding: 8px 12px;
        border: 1px solid ${colors.orange};
        border-radius: 4px;
        cursor: pointer;
        &:hover {
            img {
                transform: translateX(3px);
            }
        }
        span {
            color: ${colors.white};
            font-size: 12px;
        }
        img {
            width: 16px;
            margin-left: 8px;
            transition: 0.25s;
        }
    `,
    bgTitle: css`
        padding: 4px 8px;
        position: relative;
        img,
        h6 {
            position: relative;
            z-index: 1;
        }
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: ${colors.white};
            z-index: 0;
            transform: skew(-20deg, 0);
        }
    `,
    gameItem: css`
        position: relative;
        height: fit-content;
        transition: border-radius 650ms ease-in-out;
        overflow: hidden;
        .logo-game {
            position: absolute;
            left: 28px;
            top: 40px;
            width: 100px;
        }
        &::before {
            position: absolute;
            content: '';
            width: 130%;
            height: calc(100% - 15px);
            top: 15px;
            left: -10%;
            background: linear-gradient(120deg, transparent 0%, transparent 20%, rgba(255, 255, 255, 0.2) 21%, rgba(255, 255, 255, 0.5) 35%, transparent 36%);
            background-size: 160%;
            background-position: 120%;
            /* opacity: 0; */
            pointer-events: none;
        }
        &:hover {
            &::before {
                cursor: pointer;
                animation: ${glass} 650ms ease-in-out;
            }
        }
    `,
    btnPlayGame: css`
        position: absolute;
        left: 28px;
        bottom: 26px;
    `,
    // football section
    football: css`
        background: url(${footballBg}) no-repeat;
        background-size: cover;
        overflow: hidden;
    `,
    footballWrap: css`
        flex: 1;
        min-width: 680px;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    `,
    footballContent: css`
        display: flex;
        gap: 24px;
    `,
    footballHot: css`
        flex: 0 0 650px;
        width: 650px;
    `,
    wrapFastLT: css`
        position: relative;
        z-index: 1;
        .ball-title-icon {
            position: absolute;
            top: 10px;
            left: 50%;
            width: 200px;
            transform: translate(-50%, -50%);
        }
    `,
    canvasBlossom: css`
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        pointer-events: none;
    `,
};
export default homePageStyles;
