import authApi from 'apis/authApi';
import { RootState, useAppDispatch, useAppSelector } from 'cache/store';
import AuthHeader from 'components/auth-header/AuthHeader';
import Button from 'components/button/Button';
import HeaderMenu from 'components/header-menu/HeaderMenu';
import LoadingIcon from 'components/loading/LoadingIcon';
import Modal from 'components/modal/Modal';
import { DEVICE_INFO } from 'constants/common';
import { colors } from 'constants/theme';
import { cacheUserInfo, login, logout, rememberAccountAction, updateAgentType } from 'features/authSlice';
import Fingerprint2 from 'fingerprintjs2';
import { LoginPayload } from 'models/authModel';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { encode } from 'utils/base64';
import styles from './header.styles';
import LoginHeader from './login-header/LoginHeader';
import AccountHeader from './account-header/AccountHeader';
import classNames from 'classnames';
import userApi from 'apis/userApi';
import { useTransition, animated } from '@react-spring/web';
import { toggleShowFirstNotice, updateMessageUnread } from 'features/msgSystemSlice';
import { Game3rdMenuItem } from 'models/commonModel';
import globalStyle from 'constants/global.style';
import FastTransferGame from 'components/transfer/FastTransferGame';
import UpdateUser from 'components/update-user/UpdateUser';
import ForgotPassword from 'components/forgot-pw/forgot-pw';
import Lottie from 'lottie-react';
import fireworks from 'assets/lottie/fireworks.json';

interface UserData {
    username: string;
    balance: string;
}

function Header() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { msgUnread } = useAppSelector((state: RootState) => state.system);
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState<UserData>({} as UserData);
    const { isLogged, userInfo } = useAppSelector((state: RootState) => state.auth);
    const [showAuth, setShowAuth] = useState(false);
    const [showForgotPW, setShowForgotPW] = useState(false);
    const [showAccountInfo, setShowAccountInfo] = useState(false);
    const [gameTransferSelect, setGameTransferSelect] = useState<Game3rdMenuItem | null>(null);
    const [isReloadBalance, setIsReloadBalance] = useState(false);
    const [showUpdateUser, setShowUpdateUser] = useState(false);
    const [loginName, setLoginName] = useState('');

    const transitionMsg = useTransition(msgUnread > 0, {
        from: { scale: 0.5, opacity: 0 },
        enter: { scale: 1, opacity: 1 },
    });

    useEffect(() => {
        if (userInfo && isLogged) {
            setUserData({
                username: userInfo.loginname,
                balance: userInfo.balance,
            });
        }
    }, [userInfo, isLogged]);

    useEffect(() => {
        if (isLogged) {
            const getMsgUnread = async () => {
                try {
                    const { data } = await userApi.personMessageSearch({});
                    if (!data.msg) {
                        const msgUnreadList: any[] = data.reslist.filter((msg: any) => msg.viewtimes === 0);
                        dispatch(updateMessageUnread(msgUnreadList.length));
                    }
                } catch (error) {
                    console.log(error);
                }
            };
            getMsgUnread();
        }
    }, [isLogged]);

    // useEffect(() => {
    //     Fingerprint2.get(function (components) {
    //         getFingerPrint(components);
    //     });
    // }, []);

    // const getFingerPrint = (components: Fingerprint2.Component[]) => {
    //     const values = components.map((component) => {
    //         return component.value;
    //     });
    //     DEVICE_INFO.uuid = Fingerprint2.x64hash128(values.join(''), 88);
    //     DEVICE_INFO.timezone = values[9];
    //     DEVICE_INFO.platform = values[16];
    //     DEVICE_INFO.resolution = values[6].join('x');
    //     console.log(DEVICE_INFO);
    // };

    const submitLogin = async (payload: LoginPayload, showAlertSuccess: boolean, closeModal: boolean, rememberAccount?: boolean) => {
        setLoading(true);
        try {
            const { data } = await authApi.loginGetToken(payload);
            if (data.msg === '') {
                //change state logged in store "auth slice"
                dispatch(login(data.data));

                if (closeModal) {
                    setShowAuth(false);
                }

                if (showAlertSuccess) {
                    toast.success('Đăng nhập thành công!');
                }
                getUserInfo(payload, rememberAccount);
            } else if (data.msg === '-1') {
                setShowUpdateUser(true);
                setLoginName(data.user.loginname);
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const getUserInfo = async (payload: LoginPayload, rememberAccount?: boolean) => {
        const { username, password } = payload;

        try {
            const { data } = await authApi.getUserInfo();
            if (!data.errorCode) {
                dispatch(cacheUserInfo(data));

                //cache agent type account
                let agentTypeUser = null;
                if (data.usertype === -2) {
                    agentTypeUser = 1;
                } else if (data['param9'] && data['param9'] !== '') {
                    agentTypeUser = 2;
                }
                dispatch(updateAgentType(agentTypeUser));

                //cache remember account
                if (rememberAccount) {
                    const rememberData = {
                        account: encode(`${username}|${password}`),
                        isRemember: true,
                    };
                    dispatch(rememberAccountAction(rememberData));
                } else {
                    dispatch(rememberAccountAction({ isRemember: false, account: null }));
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const changeWalletPage = (path: 'wl-depo' | 'wl-wdr' | 'fund-transfer') => {
        navigate(`/account?mode=${path}`);
    };

    const onLogout = async () => {
        try {
            await authApi.logoutByToken();
            dispatch(logout());
            dispatch(toggleShowFirstNotice(false));
            navigate('/');
        } catch (error) {
            console.log(error);
        }
    };

    const playGame = (game: Game3rdMenuItem) => {
        setGameTransferSelect(game);
    };

    const refreshBalance = async () => {
        if (!isReloadBalance) {
            setIsReloadBalance(true);
            setTimeout(() => {
                setIsReloadBalance(false);
            }, 1000);
            try {
                const { data } = await authApi.getUserInfo();
                if (!data.errorCode) {
                    dispatch(cacheUserInfo(data));
                }
            } catch (error) {
                console.log(error);
            }
        }
    };
    return (
        <>
            <div className={styles.wrap}>
                <div className={globalStyle.container}>
                    <div className={styles.content}>
                        <div className={styles.logo} onClick={() => navigate('/')}>
                            <img src={require('assets/images/logos/logo.png')} alt="" />
                            {/* <Lottie className={styles.fireworks} animationData={fireworks} loop={true} /> */}
                        </div>
                        <div className="header-right">
                            {/* <img className="decor-1" src={require('assets/images/xmas/decor-new-year.png')} alt="" /> */}
                            {/* <img className="decor-2" src={require('assets/images/xmas/decor-2.png')} alt="" /> */}
                            {!isLogged ? (
                                <LoginHeader onShowForgotPW={() => setShowForgotPW(true)} onLogin={submitLogin} onShowAuth={() => setShowAuth(true)} />
                            ) : (
                                <div className={styles.userHeader}>
                                    <button type="button" className={`${styles.btnLeft} ${styles.btnBalance} ${isReloadBalance ? styles.rotatingBalance : {}}`} onClick={refreshBalance}>
                                        <img className="refresh-icon" src={require('assets/images/icons/refresh.png')} alt="" />
                                        <img className="coin-icon" src={require('assets/images/icons/coin.gif')} alt="" />
                                        <p>{new Intl.NumberFormat().format(Number(userData.balance) || 0)} VNĐ</p>
                                    </button>
                                    <div className={styles.wrapBtnAccount} onMouseEnter={() => setShowAccountInfo(true)} onMouseLeave={() => setShowAccountInfo(false)}>
                                        <button type="button" className={classNames(`${styles.btnLeft} ${styles.btnAccount}`, { show: showAccountInfo })}>
                                            <img className={styles.avatar} src={require(`assets/images/vip/l${userInfo?.currentlevelvip || 0}.png`)} alt="" />

                                            {/* {showAccountInfo ? (
                                                <img className={styles.avatar} src={require('assets/images/icons/avatar-dark.png')} alt="" />
                                            ) : (
                                                <img className={styles.avatar} src={require('assets/images/icons/avatar.png')} alt="" />
                                            )} */}

                                            <p className={styles.username}>{userData.username}</p>
                                            <img className={`${styles.arrow} arrow-icon`} src={require('assets/images/icons/arrow.png')} alt="" />
                                            {/* user info option */}
                                        </button>
                                        <AccountHeader show={showAccountInfo} onHide={() => setShowAccountInfo(false)} />
                                    </div>

                                    <div className={styles.noti} onClick={() => navigate('/account?mode=noti-inbox')}>
                                        <img src={require('assets/images/icons/ring.png')} alt="" />

                                        {msgUnread > 0 &&
                                            transitionMsg(
                                                (style, item) =>
                                                    item && (
                                                        <animated.span style={style} className={styles.countUnreadMsg}>
                                                            {msgUnread < 10 ? msgUnread : '9+'}
                                                        </animated.span>
                                                    )
                                            )}
                                    </div>
                                    <div className={styles.btnAction}>
                                        <Button text="Nạp Tiền" bg={colors.darkRed} minWidth="93px" borderColor={colors.darkRed} click={() => changeWalletPage('wl-depo')} bgHover="#D00C00" />
                                        <Button
                                            text="Rút Tiền"
                                            bg={colors.secondary}
                                            minWidth="93px"
                                            borderColor={colors.secondary}
                                            click={() => changeWalletPage('wl-wdr')}
                                            bgHover="#ff9900"
                                            textColor={colors.blackText}
                                        />
                                        <Button
                                            text="Chuyển Quỹ"
                                            bg={colors.darkPrimary}
                                            minWidth="93px"
                                            borderColor={colors.darkPrimary}
                                            click={() => changeWalletPage('fund-transfer')}
                                            width="unset"
                                            bgHover="#1D7777"
                                        />
                                        <Button text="Thoát" bg="none" borderColor={colors.darkPrimary} click={onLogout} />
                                    </div>
                                </div>
                            )}
                            {/* <img className="decor-3" src={require('assets/images/xmas/decor-new-year-3.png')} alt="" /> */}
                        </div>
                    </div>
                </div>
                <HeaderMenu onPlayGame={playGame} />
            </div>

            <Modal isShow={showAuth} onClose={() => setShowAuth(false)} backdropColor="rgba(255,255,255, 0.7)" width="1050px">
                <AuthHeader onClose={() => setShowAuth(false)} onLogin={submitLogin} />
            </Modal>

            {/* popup chuyển quỹ nhanh */}
            <Modal isShow={Boolean(gameTransferSelect)} onClose={() => setGameTransferSelect(null)} width="520px">
                {gameTransferSelect ? <FastTransferGame isLobbyLive gameData={gameTransferSelect} onClose={() => setGameTransferSelect(null)} /> : <></>}
            </Modal>

            {/* popup update user account */}
            <Modal isShow={showUpdateUser} onClose={() => setShowUpdateUser(false)} width="520px">
                <UpdateUser onClose={() => setShowUpdateUser(false)} loginName={loginName} />
            </Modal>

            {/* popup forgot password */}
            <Modal isShow={showForgotPW} onClose={() => setShowForgotPW(false)} width="400px">
                <ForgotPassword onClose={() => setShowForgotPW(false)} />
            </Modal>

            {loading && <LoadingIcon />}
        </>
    );
}

export default Header;
